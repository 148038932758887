<template>
    <v-dialog
            class="helper-dialog"
            v-model="helperDialog"
            fullscreen="true"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    x-small
                    icon
                    v-bind="attrs"
                    v-on="on"
            >
                <img src="../../assets/icons/warning.svg" alt="helper"/>
            </v-btn>
        </template>

        <div class="helper-dialog__container">
            <img class="tenant-logo" src="../../assets/images/tenant-logo.svg" alt=""/>
            <div class="helper-dialog__wrapper">
                <div class="helper-dialog__header">
                    <div class="helper-dialog__title">
                        <h2>{{ title }}</h2>
                        <h6>{{ !!subtitle ? subtitle : 'Tenant Evaluation' }}</h6>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            x-large
                            v-bind="attrs"
                            v-on="on"
                            @click="helperDialog = false"
                    >
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </div>

                <div class="helper-dialog__content">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
export default {
    name: 'HelperDialog',
    props: {
        title: String,
        subtitle: String,
    },
    data() {
        return {
            helperDialog: false,
        }
    },
}
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.helper-dialog {

    &__container {
        height: 100%;
        background: $primary-color;
        display: flex;
        align-items: center;

        .tenant-logo {
            position: absolute;
            top: 115px;
            left: 75px;
        }
    }

    &__wrapper {
        margin: 0 auto;
        max-width: 850px;
        width: 100%;
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__title {
        h2, h6 {
            color: $white-color;
            font-family: $extra-font;
        }

        h2 {
            font-size: 20px;
            font-weight: 600;
        }

        h6 {
            font-size: 14px;
            font-weight: 500;
        }
    }
}
</style>
