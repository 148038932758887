<template>
    <div class="package">
        <HeadingComponent
            title="Let’s Build the Package <br>That’s Right for Your Business."
            subtitle="Prices will be included on the final proposal once you have setup your services"
        />

        <ApplicationSelector @onApplicationSelected="onApplicationSelected" />

        <template v-if="application === 'lease' || !application">
            <div class="package__card-container">
                <div class="package__card-col">
                    <v-card elevation="0" class="card">
                        <div class="card__group">
                            <h4>Criminal Background</h4>
                            <ul class="simple-list">
                                <li>Instant Nationwide & Instant International</li>
                                <li>National Sex Offender/Predator Search</li>
                                <li>Federal Database Search & FBI Most Wanted</li>
                                <li>County and State Databases</li>
                                <li>
                                    65+ Terrorists Databases <span class="highlight ml-1">New</span>
                                </li>
                            </ul>
                        </div>

                        <div class="card__group">
                            <h4>Collection of Electronic Signatures/Initials</h4>
                            <ul class="simple-list">
                                <li>Resident Tenant Evaluation Template Forms</li>
                                <li>Vehicle Tenant Evaluation Template Forms</li>
                                <li>Pet Tenant Evaluation Template Forms</li>
                            </ul>
                        </div>

                        <div class="card__group">
                            <h4>Basic Document Collection</h4>
                            <ul class="simple-list">
                                <li>Photo ID</li>
                                <li>Marriage Certificate</li>
                                <li>Pet Photo ( optional )</li>
                            </ul>
                        </div>

                        <div class="card__group">
                            <h4>Collection of Application Fees</h4>
                            <ul class="simple-list">
                                <li>Staff Training <span class="highlight ml-1">New</span></li>
                                <li>E-Statements (ACH Direct Deposit)</li>
                            </ul>
                            <p>*An extra $10.00 fee will be charged if rebates checks apply</p>
                        </div>
                    </v-card>
                </div>

                <div class="package__card-col">
                    <v-card elevation="0" class="card">
                        <h4>Credit Report with <br />Ssn Validation</h4>
                        <div class="card__actions">
                            <div>
                                <p class="mb-0">
                                    Subject to inspection approval
                                    <HelperDialog title="Credit Report Inspection">
                                        <template v-slot:content>
                                            <v-card class="my-12" elevation="24">
                                                <video
                                                    poster="../../assets/images/video-banner.png"
                                                    autoplay="autoplay"
                                                    loop="loop"
                                                    controls="controls"
                                                    style="display: block"
                                                >
                                                    <source
                                                        src=""
                                                        type='video/webm; codecs="vp8, vorbis"'
                                                    />
                                                    <source
                                                        src=""
                                                        type='video/ogg; codecs="theora, vorbis"'
                                                    />
                                                </video>
                                            </v-card>
                                            <v-btn depressed x-large class="secondary">
                                                Donwload the guide
                                            </v-btn>
                                        </template>
                                    </HelperDialog>
                                </p>
                                <a href="">Learn More</a>
                            </div>
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </v-card>

                    <v-card elevation="0" class="card">
                        <h4>Nationwide Evictions, Suits, <br />Liens, and Judgements</h4>
                        <div class="card__actions">
                            <a href="">Learn More</a>
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </v-card>

                    <v-card elevation="0" class="card">
                        <h4 class="pb-0">Standard Reference <br />Verifications</h4>
                        <div class="card__content mb-10">
                            <p>Select the verifications you would like to do for your applicants</p>
                            <div
                                v-for="option in verificationsOptions"
                                :key="option.value"
                                class="form__group"
                            >
                                <v-checkbox
                                    color="secondary"
                                    v-model="verifications"
                                    :label="option.name"
                                    :value="option.value"
                                    hide-details="auto"
                                    v-on:change="verificationsChange()"
                                ></v-checkbox>
                            </div>
                        </div>
                        <div class="card__actions">
                            <a href="">Learn More</a>
                            <v-btn depressed class="secondary">Add</v-btn>
                        </div>
                    </v-card>
                </div>
            </div>

            <TotalResultComponent total="35" member="Gold Member" />

            <div class="dashboard__extra my-14">
                <h3>Do you prefer a customized solution?</h3>
                <p>
                    If you did not found what you need, you can build your solution with our a la
                    carte option.
                </p>
                <a href="">Build a customized solution</a>
            </div>

            <div class="dashboard__actions">
                <div class="spacer"></div>
                <v-btn @click="$router.push('add-ons')" color="secondary" x-large elevation="0">
                    continue
                </v-btn>
            </div>
        </template>
    </div>
</template>

<script>
import ApplicationSelector from '@/components/ApplicationSelector';
import HeadingComponent from '@/components/HeadingComponent';
import TotalResultComponent from '@/components/TotalResultComponent';
import HelperDialog from '@/ui/dialogs/HelperDialog';

export default {
    name: 'Package',
    components: {
        ApplicationSelector,
        HeadingComponent,
        TotalResultComponent,
        HelperDialog,
    },
    data() {
        return {
            application: '',
            verifications: [],
            verificationsOptions: [
                { value: 'character', name: 'Character' },
                { value: 'landlord', name: 'Landlord' },
                { value: 'employment', name: 'Employment' },
                { value: 'bank-verification', name: 'Bank Verification' },
            ],
        };
    },
    methods: {
        onApplicationSelected(application) {
            this.application = application.toLowerCase().replace(/ /g, '-');
        },
        /**
         * @todo remove this function once the purpose is known
         */
        verificationsChange() {
            console.log(this.verifications);
        },
    },
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.package {
    .heading {
        margin-bottom: 30px;
    }

    &__card-container {
        margin-top: 12px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 11px;
    }

    &__card-col {
        display: flex;
        flex-direction: column;
    }

    .card {
        height: 100%;
    }

    .form__group {
        .v-input {
            margin-top: 10px;
        }
    }
}
</style>
